<div>
  <mat-toolbar>
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <a href="#" fxLayout="row" fxLayoutAlign="center center"><img src="assets/logo.png" height="48px"></a>

      <div [fxHide.xs]="false" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <a mat-icon-button aria-label="Instagram Icon" href="https://www.instagram.com/v.annjewellery" target="_blank">
          <mat-icon style="color: rgb(221, 43, 123); width: 28px; height: 28px" svgIcon="instagram"
                    class="instagram"></mat-icon>
        </a>
        <a mat-icon-button aria-label="Facebook Icon" href="https://www.facebook.com/v.annjewellery" target="_blank">
          <mat-icon style="color: #4267b2; width: 28px; height: 28px" svgIcon="facebook" class="facebook"></mat-icon>
        </a>
        <a mat-icon-button aria-label="Flag Icon" style="cursor: pointer" (click)="changeFlag()">
          <mat-icon [svgIcon]="flag">mail_outline</mat-icon>
        </a>
      </div>

    </div>
  </mat-toolbar>

  <owl-carousel-o [options]="slideOptions">
    <ng-container *ngFor="let slide of slides">
      <ng-template carouselSlide>
        <img [src]="slide">
      </ng-template>
    </ng-container>
  </owl-carousel-o>

  <div class="mat-typography"
       *ngIf="flag==='us'"
       style="padding: 0 15px;
     padding-bottom: 15px;
     font-size: 15px;
     text-align: justify;
     line-height: 21px; color: rgba(0,0,0,.5);
     font-family: Tahoma, Geneva, sans-serif;">
    <p>
      Վի Էնն ընկերությունը 2013 թվականից զբաղվում է զարդերի դիզայնով և արտադրությամբ: Ընկերությունում ընդունվում են
      պատվերներ ոսկյա, արծաթյա, մարգարտյա, այլ բնական քարերով զարդերի պատրաստման համար: Զարդապատրաստման ողջ գործընթացն
      իրականացվում է բազմամյա փորձառություն և բարձր որակավորում ունեցող մասնագետների կողմից նորագույն բարձր
      տեխնոլոգիական սարքավորումներով` ապահովելով առավելագույն ճշգրտություն և բարձր որակ։
    </p>
    <p>
      Մենք հոգ կտանենք, եթե Դուք դեռ չեք կողմնորոշվել թե ինչպիսի զարդերով եք ցանկանում լրացնել Ձեր զարդապահարանը։ Մեր
      ջերմ ցուցասրահում մատուցվում է զարդեր կրելու անհատական խորհրդատվություն` հաշվի առնելով զարդը կրողի ֆիզիկական
      պարամետրերը, մասնագիտությունը, նախասիրությունները, զարդը կրելու առիթի ձևաչափը։
    </p>
    <p>
      Վի Էնն ընկերությունն իր գործունեության ողջ ընթացքում մասնակցել է բազմաթիվ միջազգային ցուցահանդեսների Հայաստանում և
      Ճապոնիայում, ներկայացրել անհատական թեմատիկ հավաքածուներ, ստացել լավագույն դիզայն մրցանակ։
    </p>
    <p>
      Վի Էնն ընկերությունը հանդիսանում է Համաշխարհային Հայ Ոսկերիչների Միության անդամ, Հայաստանի Ոսկերիչների Միության
      անդամ՝ խստորեն պահպանելով ոսկեգործության ոլորտի բոլոր ընդունված միջազգային նորմերը, ստանդարտներն ու կանոնները։
    </p>
    <p>
      2013 թվականից Վի Էնն-ը հանդիսանում է ճապոնական ծովի մարգարիտներ արտադրող «ՈՒՎԱԿՈՅԱ» ապրանքանիշի միակ պաշտոնական
      ներկայացուցիչը Հայաստանում։ Ընկերությունում մատուցվում է մարգարիտներ կրելու, խնամելու վերաբերյալ խորհրդատվություն,
      ճապոնական ստանդարտներով մարգարտյա շարանների հավաքում և հատուկ փականների տեղադրում։
    </p>
    <p>
      Վի Էնն․ նուրբ զգացողություններ </p>
  </div>

  <div class="mat-typography"
       *ngIf="flag==='armenia'"
       style="padding: 0 15px;
     padding-bottom: 15px;
     font-size: 15px;
     text-align: justify;
     line-height: 21px; color: rgba(0,0,0,.5);
     font-family: Tahoma, Geneva, sans-serif;">
    <p>
      v-ann company is engaged in design and manufacturing jewellery since 2013. The company takes orders for jewellery
      made of gold, silver, pearls and other natural stones. The whole process of jewellery making is carried out by
      highly skilled and experienced specialists using Hi-Tech equipment and ensure maximum accuracy and high quality.
    </p>
    <p>
      We will take care if you have not decided yet which jewellery you want to fill your jewellery box with. Our
      showroom provides individual advice on wearing jewellery, taking into account the physical parameters, profession,
      preferences and occasion for wearing jewellery.
    </p>
    <p>
      v-ann company has participated in many international exhibitions in Armenia and Japan since 2013, as well as
      presented individual subject collections and received the best design award.
    </p>
    <p>
      v-ann company is a member of the World Armenian Jewelers Association (AJA) and the Armenian Jewellers Union,
      strictly adhering all the accepted international norms, standards and rules in the field of goldsmith &
      silversmith.
    </p>
    <p>
      Since 2013 v-ann company is the only official representative of Japanese sea pearl producer company with its brand
      UWAKOYA in Armenia. The company provides advice on the culture of wearing pearls and caring for sea pearls,
      assembling pearl strings and setting special locks, in accordance with Japanese standards.
    </p>
    <p>
      v-ann – delicate sensation</p>
  </div>
</div>
