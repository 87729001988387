import {Component, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public homePadding: number;
  public flag: string;
  public slideOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    lazyLoad: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  };

  public slides: string[] = [
    'assets/slide-1.jpg',
    'assets/slide-2.jpg',
    'assets/slide-3.jpg',
    'assets/slide-4.jpg',
    'assets/slide-5.jpg',
    'assets/slide-6.jpg',
    'assets/slide-7.jpg',
  ];

  private mediaSubscription: Subscription;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private mediaObserver: MediaObserver) {
    this.flag = 'us';
    iconRegistry.addSvgIcon(
      'facebook',
      sanitizer.bypassSecurityTrustResourceUrl('assets/facebook.svg'),
    );
    iconRegistry.addSvgIcon(
      'instagram',
      sanitizer.bypassSecurityTrustResourceUrl('assets/instagram.svg'),
    );
    iconRegistry.addSvgIcon(
      'youtube',
      sanitizer.bypassSecurityTrustResourceUrl('assets/youtube.svg'),
    );
    iconRegistry.addSvgIcon(
      'gmail',
      sanitizer.bypassSecurityTrustResourceUrl('assets/gmail.svg'),
    );
    iconRegistry.addSvgIcon(
      'armenia',
      sanitizer.bypassSecurityTrustResourceUrl('assets/armenia.svg'),
    );
    iconRegistry.addSvgIcon(
      'us',
      sanitizer.bypassSecurityTrustResourceUrl('assets/us.svg'),
    );
  }

  public ngOnInit(): void {
    this.mediaSubscription = this.mediaObserver.media$.subscribe((mediaChange: MediaChange) => {
      switch (mediaChange.mqAlias) {
        case 'xs': {
          this.homePadding = 0;
          break;
        }
        case 'sm': {
          this.homePadding = 20;
          break;
        }
        case 'md': {
          this.homePadding = 25;
          break;
        }
        default: {
          this.homePadding = 30;
          break;
        }
      }
    });
  }
  public changeFlag(): void {
    this.flag = this.flag === 'us' ? 'armenia' : 'us';
  }
}
